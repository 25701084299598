$blue: #1763c3;

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

html,
body {
  height: 100%;
  min-width: 320px;
}

.btn {
  border-width: 2px;
}

header {
  height: 115px;
  margin-bottom: -115px;
  z-index: 1;
  img {
    max-height: 115px;
  }
}

.custom-bg-1,
.custom-bg-2,
.custom-bg-3 {
  background-color: black;
  background-size: cover;
}

#sendmailModal {
  .modal-content {
    overflow: hidden;
  }
}

#sendmailModal {
  .modal-header {
    border-bottom: none;
  }
  @include media-breakpoint-up(lg) {
    .modal-header {
      padding-left: 10%;
      padding-top: 40px;
    }
    .modal-body {
      background-position: right center;
      background-size: 40% auto;
      background-repeat: no-repeat;
      padding: 10px 40% 40px 10%;
    }
  }
}

.webpalpha {
  .custom-bg-1 {
    background-image: url(/images/bg/1.webp);
  }
  .custom-bg-2 {
    background-image: url(/images/bg/2.webp);
  }
  .custom-bg-3 {
    background-image: url(/images/bg/3.webp);
  }
  @include media-breakpoint-up(lg) {
    #sendmailModal {
      .modal-body {
        background-image: url(/images/bg/phone.webp);
      }
    }
  }
}

.no-webpalpha,
.no-js {
  .custom-bg-1 {
    background-image: url(/images/bg/1.jpg);
  }
  .custom-bg-2 {
    background-image: url(/images/bg/2.jpg);
  }
  .custom-bg-3 {
    background-image: url(/images/bg/3.jpg);
  }
  @include media-breakpoint-up(lg) {
    #sendmailModal {
      .modal-body {
        background-image: url(/images/bg/phone.jpg);
      }
    }
  }
}

.custom-banner {
  padding-top: 50px;
  padding-bottom: 50px;
  picture {
    source,
    img {
      max-width: 960px;
    }
  }
}

.custom-card {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  background: white;
  overflow: hidden;
  border-radius: 15px;
  padding: 25px;
}

.custom-top-content {
  padding-top: 120px;
  padding-bottom: 30px;
  // @include media-breakpoint-up(md) {
  //   padding-top: 130px;
  // }
}

/* .custom-top-content-xl {
  padding-top: 180px;
  padding-bottom: 160px;
  @include media-breakpoint-up(md) {
    padding-top: 160px;
  }
} */

.custom-max-width {
  max-width: 1920px;
}

.custom-icon {
  width: 80px;
}

.btn {
  font-weight: 700;
}

.custom-logo {
  img {
    width: 100px;
    @include media-breakpoint-up(md) {
      width: 170px;
    }
  }
}

.custom-font-xl {
  @include media-breakpoint-up(md) {
    p,
    li,
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 150%;
    }
  }
}

.custom-header {
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  font-size: 1.5rem;
  @include media-breakpoint-up(md) {
    font-size: 5rem;
  }
}

.custom-header-sm {
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  font-size: 1.25rem;
  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }
}

.custom-user-text {
  overflow-wrap: break-word;
}

ol > li:before {
  counter-increment: section;
  content: counters(section, ".") ". ";
}
ol {
  counter-reset: section;
  list-style-type: none;
}

.nobr {
  white-space: nowrap;
}

body.chat-open {
  @include media-breakpoint-down(xs) {
    overflow: hidden;
  }
}

.custom-info {
  background: $primary;
  color: $white;
  border-radius: 50%;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
};

.chat-padding {
  padding: 0.5rem;
  @include media-breakpoint-up(sm) {
    padding: 1rem;
  }
};

// #chat {
//   min-height: 40em;
//   background-color: white;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   &.fixed {
//     min-height: none;
//     @extend .rounded;
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     width: 100%;
//     z-index: 2;
//     @include media-breakpoint-up(sm) {
//       width: 20rem;
//       top: 0.5rem;
//       bottom: 0.5rem;
//       right: 0.5rem;
//       border: 1px solid $dark;
//     };
//     .reply-box {
//       button {
//         @extend .rounded-circle;
//         padding: 0;
//         flex: 0 0 3em;
//         &:before {
//           content: '✉'
//         }
//       }
//     }
//   }
//   .chat-container {
//     display: flex;
//     flex-direction: column;
//     flex-grow: 1;
//     height: 0; //hack to get overflow working
//     width: 100%;
//   };
//   .message-list {
//     @extend .chat-padding;
//     display: flex;
//     flex-direction: column-reverse;
//     flex-grow: 1;
//     overflow: auto;
//   };
//   .message-incoming, .message-outgoing {
//     @extend .rounded;
//     @extend .shadow-sm;
//     @extend .border-0;
//     flex: 0 1 auto;
//     white-space: pre-wrap;
//     @extend .chat-padding;
//     @include media-breakpoint-up(sm) {
//       max-width: 80%;
//       min-width: 30%;
//     };
//   };
//   .message-incoming:not(:first-child),
//   .message-outgoing:not(:first-child) {
//     @extend .mb-1;
//   };
//   .message-incoming + .message-outgoing,
//   .message-outgoing + .message-incoming {
//     @extend .mb-4;
//   };
//   .message-outgoing {
//     @extend .align-self-start;
//     background-color: #d3defd;
//   };
//   .message-incoming {
//     @extend .align-self-end;
//     background-color: #d9fdd3;
//   };
//   .reply-box {
//     @extend .chat-padding;
//     form {
//       display: flex;
//       flex-direction: row;
//       textarea {
//         @extend .form-control;
//         @extend .mr-2;
//         flex: 1 1 auto;
//         min-height: 3em;
//         max-height: 20em;
//       };
//       button {
//         height: 3em;
//         align-self: center;
//         &:before {
//           content: 'Отправить';
//         }
//       };
//     }
//   };
// };

#chat {
  min-height: 40em;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.fixed {
    min-height: none;
    @extend .rounded;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    @include media-breakpoint-up(sm) {
      width: 20rem;
      top: 0.5rem;
      bottom: 0.5rem;
      right: 0.5rem;
      border: 1px solid $dark;
    };
    .reply-box {
      button {
        @extend .rounded-circle;
        padding: 0;
        flex: 0 0 3em;
        &:before {
          content: '✉'
        }
      }
    }
  }
  .chat-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 0; //hack to get overflow working
    width: 100%;
  };
  .message-list {
    @extend .chat-padding;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    overflow: auto;
    .files {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      gap: .25em;
      & > * {
        word-break: break-all;
      }
    };
  };
  .message-incoming, .message-outgoing {
    @extend .rounded;
    @extend .shadow-sm;
    @extend .border-0;
    flex: 0 1 auto;
    white-space: pre-wrap;
    @extend .chat-padding;
    @include media-breakpoint-up(sm) {
      max-width: 80%;
      min-width: 30%;
    };
  };
  .message-incoming:not(:first-child),
  .message-outgoing:not(:first-child) {
    @extend .mb-1;
  };
  .message-incoming + .message-outgoing,
  .message-outgoing + .message-incoming {
    @extend .mb-4;
  };
  .message-outgoing {
    @extend .align-self-start;
    background-color: #d3defd;
  };
  .message-incoming {
    @extend .align-self-end;
    background-color: #d9fdd3;
  };
  .reply-box {
    &.dragging {
      position: relative;
      & > * {
        opacity: 0;
      };
      &::after {
        // content: 'Перетащите файлы сюда';
        content: '';
        background: url('/images/drop-file.svg') no-repeat center;
        background-size: 2em;
        border: 1px dashed cyan;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    };
    @extend .chat-padding;
    .files {
      @extend .mb-2;
      list-style: none;
      padding: 0;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      gap: .25em;
      & > * {
        @extend .rounded;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        &::before, &::after {
          z-index: 1;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          text-align: center;
        }
        &::before {
          font-size: 200%;
        }
        &:hover {
          &::after {
            background: black;
            content: '❌';
            opacity: 0.5;
            text-align: right;
          }
        }
      }
      & > .img {
        height: 4em;
        img {
          height: 100%;
        }
      }
      & > .doc {
        overflow: hidden;
        max-width: 30%;
        word-break: break-all;
        width: 4em;
        height: 4em;
        &::before {
          content: '📄';
        }
      }
    }
    form {
      display: flex;
      flex-direction: row;
      // input[type='upload'] {
      //   @extend .form-control;
      //   @extend .mb-2;
      //   flex: 1 0 100%;
      // }
      & > * {
        margin: 0;
      };
      gap: 0.25em;
      textarea {
        @extend .form-control;
        flex: 1 1 auto;
        min-height: 3em;
        max-height: 20em;
      };
      button, label {
        height: 3em;
        align-self: center;
      };
      label {
        @extend .btn;
        @extend .btn-primary;
      };
      button {
        &:before {
          content: 'Отправить';
        }
      };
    }
  };
}